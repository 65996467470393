<template>
  <router-view />
</template>
<script>
export default {
  name: 'Globalbot'
}
</script>

<style>
body {
  font-family: 'Poppins', sans-serif!important;
  letter-spacing: -.24px!important;
  -webkit-font-smoothing: antialiased!important;
}
</style>
