import { boot } from 'quasar/wrappers'
import Socket from 'socket.io-client'

const AUTH_SOCKET = process.env.VUE_APP_SOCKET_AUTH;
const LIVE_SOCKET = process.env.VUE_APP_SOCKET_LIVE;

export default boot(({ app, store, router }) => {
  app.config.globalProperties.$SOCKET = {}
  app.config.globalProperties.$SOCKET.auth = Socket(AUTH_SOCKET, {
    autoConnect: false,
    query: {},
    transports: ['websocket', 'polling']
  })
  app.config.globalProperties.$SOCKET.auth.on('connection', () => {
    console.info('Auth Connected')
  })
  app.config.globalProperties.$SOCKET.auth.on('connect', () => {
    console.info('Auth Connected')
  })
  // app.config.globalProperties.$SOCKET.auth.on('alarm', (payload) => {
  //   try {
  //     store.state.main.alarm.dialogYellow = true
  //     let defaultDate = new Date(payload.controller.end)
  //     defaultDate.setMinutes(defaultDate.getMinutes() + payload.tolerance)
  //     store.state.main.alarm.tolerance = moment.utc(defaultDate.getTime() - new Date().getTime())
  //     store.state.main.alarm.counter = setInterval(() => {
  //       store.state.main.alarm.tolerance.subtract(1, 's')
  //       store.state.main.alarm.display = store.state.main.alarm.tolerance.format('HH:mm:ss')
  //       if (store.state.main.alarm.tolerance.format('HH:mm:ss') === '00:00:00') {
  //         clearInterval(store.state.main.alarm.counter)
  //       }
  //     }, 1000)
  //     store.state.main.alarm.isActive = true
  //   } catch (e) {
  //     console.error(e)
  //   }
  // })

  // CONNECT TO ZION
  let reconnect = false
  app.config.globalProperties.$SOCKET.zion = Socket(LIVE_SOCKET, {
    autoConnect: false,
    query: {},
    transports: ['websocket', 'polling'],
    secure: true
  })
  app.config.globalProperties.$SOCKET.zion.on('connection', () => {
    console.info('Zion Connected')
  })
  app.config.globalProperties.$SOCKET.zion.on('reconnect', () => {
    console.info('reconnect')
    reconnect = true
  })
  app.config.globalProperties.$SOCKET.zion.on('connect', () => {
    if (reconnect) {
      reconnect = false
      app.config.globalProperties.$SOCKET.zion.emit('changeStatus', store.state.main.zion.status)
      if (router.currentRoute && router.currentRoute.value && router.currentRoute.value.path && router.currentRoute.value.path.split('/')[1] === 'live' && router.currentRoute.value.params._id) {
        app.config.globalProperties.$SOCKET.zion.emit('enterRoom', {roomId: router.currentRoute.value.params._id})
      }
    } else {
      app.config.globalProperties.$SOCKET.zion.emit('getStatus', status => {
        if (status && status.options) store.commit('main/setZion', {status: status.options.status})
      })
    }
  })
  app.config.globalProperties.$SOCKET.zion.on('changeStatus', e => {
    if (e.hasOwnProperty('status')) {
      store.commit('main/setZion', {status: e.status})
    }
  })
})
