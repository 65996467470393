import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/brands.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import '@fortawesome/fontawesome-pro/css/duotone.css'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { boot } from 'quasar/wrappers'
export default boot(async () => {

})
